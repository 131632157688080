@import 'variables.scss';

.checkbox {
	position: relative;
	display: block;
	user-select: none;

	input {
		position: absolute;
		left: 0; top: 0;
		transform: scale(0);
		opacity: 0;

		&:checked ~ label {
			color: $blue;

			&:after {
				opacity: 1;
			}
		}
		&:focus ~ label {

			&:before {
				box-shadow: 0 0 0 3px $focus;
			}
		}
	}
	label {
		position: relative;
		display: block;
		padding-left: $checkboxSize;
		text-indent: 10px;
		min-height: $checkboxSize;
		min-width: $checkboxSize;
		line-height: $checkboxSize;
		font-family: $font;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0; left: 0;
			display: block;
			width: $checkboxSize; height: $checkboxSize;
			background: rgba(0,0,0,.1);
			border-radius: 2px;
			color: #fff;
			z-index: 1;
			text-indent: 0;
			font-size: 10px;
			line-height: $checkboxSize;
			text-align: center;
		}
		&:after {
			font-family: 'icomoon';
			content: "\e92b";
			background: $blue;
			z-index: 2;
			opacity: 0;
			transition: .1s;
		}
	}
	&.inline {
		display: inline-block;
		vertical-align: middle;
		width: auto;
	}
	&.min-height {
		min-height: 20px;
	}
}

.checkbox.round {

	input:checked ~ label {

		&:before {
			background: lighten($blue, 45);
		}
		&:after {
			background: $blue;
			transform: translateX(#{$checkboxSize + 2px});
		}
	}
	label {
		padding-left: #{$checkboxSize * 2};

		&:before {
			width: calc(#{$checkboxSize * 2});
			height: calc(#{$checkboxSize - 2px});
			border-radius: $checkboxSize;
		}
		&:after {
			width: calc(#{$checkboxSize + 2px});
			height: calc(#{$checkboxSize + 2px});
			border-radius: 50%;
			opacity: 1;
			background: $grey;
			margin-left: -2px;
			margin-top: -2px;
			color: transparent;
		}
	}
}


.timeline-checkbox {
	position: relative;
	padding: 0 !important;
	border-bottom: 1px solid #eee !important;

	input {
		position: absolute;
		top: 0; left: 0;
		opacity: 0;

		&:checked + label {
			background: $blue;
			color: #fff;
		}
	}
	label {
		display: block;
		font-size: 10px;
		text-align: center;
		line-height: 16px;
		height: 16px;
		margin: 0;
	}


	&:first-child {
		border-left: 1px solid #eee !important;
	}
	&:last-child {
		border-right: 1px solid #eee !important;
	}
}


.checkbox.error {

	label {
		color: lighten($red, 20);
	}
}

.checkbox.radio {

	label {

		&:before {
			border-radius: 50%;
		}
		&:after {
			color: transparent;
			border-radius: 50%;
			top: 4px; left: 4px;
			width: 6px;
			height: 6px;
		}
	}
}
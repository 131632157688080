@import 'app/styles/variables.scss';

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	outline: none !important;
	-webkit-tap-highlight-color: transparent !important;
}

html, body {
	width: 100%; height: 100%;
	font-size: 13px;
	font-family: $font;
	color: $dark;
	background: $light;
	font-weight: 500;
	line-height: 1.42;
	/*padding-top: 15px;*/

	&.overlayed {
		overflow: hidden !important;
	}
}

.offset-bottom {
	margin-bottom: 15px;

	&[class*="col-"],
	&.larger {
		margin-bottom: 30px;
	}
}
.offset-top {
	margin-top: 15px;

	&[class*="col-"],
	&.larger {
		margin-top: 30px;
	}
}

.nowrap {
	white-space: nowrap;
}

a {
	display: inline-block;
	text-decoration: none;
	color: $blue;
	position: relative;

	&:hover,
	&:focus {
		text-decoration: none;
		color: darken($blue, 20);
	}
}

.relative {
	position: relative;

	&.inline {
		display: inline-block;
		vertical-align: middle;
	}
}
.clear {
	padding: 0;
	margin: 0;
	list-style: none;

	&.single-line {
		white-space: nowrap;
		margin-top: 10px;

		> li {
			white-space: nowrap;
			margin-bottom: 10px;
			overflow: hidden;
			text-overflow: ellipsis;

			&:last-child {
				margin: 0;
			}
		}
	}
}
.clear-col {
	padding-left: 0;
	padding-right: 0;
}
.none {
	display: none;
}

.adaptive {
	display: block;
	width: 100%; height: auto;
	margin: 0;

	&.round {
		border-radius: 50%;
		overflow: hidden;
	}
}

hr {
	height: 1px;
	border-radius: 0;
	border: none;
	background: $border;
	margin: 15px 0;

	&.collapsed {
		margin: 0;
	}
	&.light {
		background: #f0f0f0;
	}
	&[data-divider] {
		position: relative;
		overflow: visible;

		&:before {
			content: attr(data-divider);
			display: block;
			font-size: 14px;
		}
	}
}

.row.collapsed-row {
	margin-left: 0;
	margin-right: 0;

	> * {
		padding-left: 0;
		padding-right: 0;
	}
}

.row.paddless-row {
	margin-right: -5px;
	margin-left: -5px;

	> * {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.border-left {
	border-left: 1px solid $border-color;
}
.border-top {
	border-top: 1px solid $border-color;
}
.border-bottom {
	border-bottom: 1px solid $border-color;
}
.border-right {
	border-right: 1px solid $border-color;
}

.table-layout {
	display: table;
	width: calc(100% + 20px);
	table-layout: fixed;
	margin-left: -10px;
	border-collapse: collapse;

	.column {
		padding: 0 10px;
		display: table-cell;
		vertical-align: middle;
	}
}

.align-right {
	text-align: right;
}
.align-center {
	text-align: center;
}
.align-left {
	text-align: left;
}

.bold {
	font-weight: bold;
}

.grey {
	color: $grey;
}

/*cover image*/
.image-cover {
	display: block;
	position: relative;
	padding-top: 100%;
	overflow: hidden;
	transform: translateZ(0);

	img {
		position: absolute;
		top: 0; left: 50%;
		height: 100%; width: auto;
		transform: translateX(-50%);

		&.vertical {
			top: 50%; left: 0;
			height: auto; width: 100%;
			transform: translateY(-50%);
		}
	}
	&.round {
		border-radius: 50%;
	}
	&.smaller {
		width: 90%;
		padding-top: 90%;
	}
}
/*contain image*/
.image-contain {
	display: block;
	position: relative;
	padding-top: 100%;
	overflow: hidden;
	transform: translateZ(0);

	img {
		position: absolute;
		top: 50%; left: 50%;
		height: auto; width: auto;
		max-width: 100%; max-height: 100%;
		transform: translate(-50%,-50%);
	}
}

/*vertical align*/
.verticaly-top,
.verticaly-middle,
.verticaly-bottom {
	width: 100%; height: 100%;
	white-space: nowrap;

	&:after {
		content: '';
		display: inline-block;
		width: 0; height: 100%;
	}
	.inner {
		display: inline-block;
		width: 100%;
		white-space: normal;
	}
}
.verticaly-top:after,
.verticaly-top .inner {
	vertical-align: top;
}
.verticaly-middle:after,
.verticaly-middle .inner {
	vertical-align: middle;
}
.verticaly-bottom:after,
.verticaly-bottom .inner {
	vertical-align: bottom;
}

.header-banner,
.methodolody-start {
	background-size: 120% auto;
	background-position: top center;
}

.page-content {
	padding-top: 80px;
	padding-bottom: 20px;
	padding-left: 320px;

	&.collapsed {
		padding-left: 60px;
	}
	&.static {
		padding: 0;
	}
}

@media (max-width: 1700px){

	.d-hide {
		display: none;
	}
}
@media (max-width: 1200px){

	.sd-hide {
		display: none;
	}
}

@import 'app/styles/grid.scss';
@import 'app/styles/icons.scss';
@import 'app/styles/input.scss';
@import 'app/styles/buttons.scss';
@import 'app/styles/main-header.scss';
@import 'app/styles/sidebar.scss';
@import 'app/styles/dropdown.scss';
@import 'app/styles/table.scss';
@import 'app/styles/alert.scss';
@import 'app/styles/scrollable.scss';
@import 'app/styles/checkbox.scss';
@import 'app/styles/tags-select.scss';
@import 'app/styles/calendar.scss';
@import 'app/styles/notificator.scss';
@import 'app/styles/popover.scss';
@import 'app/styles/modal.scss';
@import 'app/styles/preloader.scss';
@import 'app/styles/svg-chart.scss';
@import 'app/styles/rooms.scss';
@import 'app/styles/schedule.scss';
@import 'app/styles/tabs.scss';
@import 'app/styles/badge.scss';
@import 'app/styles/quiz.scss';

app-root,
main-page,
login-page,
registration-page,
password-reset-page,
password-change-page,
spoiler {
	display: block;
	width: 100%; height: auto;
	height: 100%;
}
login-page,
registration-page,
password-reset-page,
password-change-page {
	height: 100vh;
}
form-room-add,
form-lesson-add {
	display: block;
	width: 100%; height: auto;
}
form-lesson-add {
	max-width: 1200px;
	padding-bottom: 180px;
}


.h1-title,
.h2-title,
.h3-title,
.h4-title {
	font-family: $font;
	font-weight: 400;
	margin-bottom: 15px;
	color: inherit;

	.icon {
		position: relative;
		bottom: .1em;
	}

	&.collapsed {
		margin: 0;
	}
	&:hover, &:focus {
		color: inherit;
	}
	&[href] {

		&:hover {
			color: $blue;
		}
	}
	&.lined {
		position: relative;
		white-space: nowrap;
		overflow: hidden;

		span {
			display: inline-block;
			vertical-align: middle;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 2000px; height: 1px;
				background: #eee;
				top: 50%; left: 100%;
				margin-left: 15px;
			}
		}
		&.h4-title {
			color: $grey;
		}
	}
}

.h1-title {
	font-weight: 400;
	font-size: 24px;
}

.h2-title {
	font-weight: 400;
	font-size: 20px;
}
.h3-title {
	font-weight: 400;
	font-size: 16px;
}
.h4-title {
	font-weight: 400;
	font-size: 13px;
}


.card {
	background: #fff;
	padding: 20px;
	box-shadow: 0 5px 15px rgba(0,0,0,.07);
	border-radius: 5px;
	height: 100%;

	.heading {
		padding: 20px;
		margin: -20px -20px 20px;
		border-bottom: 1px solid $border-color;
	}
	> .row {
		margin-top: -20px;
		margin-bottom: -20px;

		> * {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}


/*users*/
.users-mini-list,
.rooms-mini-list {
	cursor: default;

	> li {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 13px;
		padding: 5px 28px 5px 10px;
		color: $grey;
		position: relative;

		img {
			display: inline-block;
			width: 20px; height: 20px;
			border-radius: 50%;
			overflow: hidden;
			vertical-align: middle;
			position: relative;
			bottom: 2px;
			margin-right: 5px;
		}
		.checkbox {
			position: absolute;
			right: 10px; bottom: 10px;
			z-index: 2;
		}
		> .title {
			color: $dark;
			font-size: 14px;
			padding: 5px 0 0;
		}
		&:hover {
			color: $dark;
		}
	}

}
.rooms-mini-list {

	> li {
		font-size: 14px;
		color: $dark;

		&:nth-child(odd){
			background: rgba(0,0,0,.03);
		}

		> span {
			display: inline-block;
			vertical-align: middle;
			overflow: hidden;
			text-overflow: ellipsis;
			width: calc(100% - 48px);
			padding-left: 5px;
			line-height: 1.3;
		}
		small {
			display: block;
			color: $grey;
			font-size: 10px;
		}
		.checkbox {
			bottom: 13px;
		}
		img {
			width: 28px; height: 28px;
		}
	}
}
.users-mini-list-checker {
	margin-right: 11px;
	margin-bottom: 10px;
}


/* labels-list */
.labels-list {
	display: block;
	cursor: default;

	> li {
		display: inline-block;
		padding: 0 7px;

		.icon {
			display: inline-block;
			width: 12px; height: 12px;
			line-height: 12px;
			text-align: center;
			margin-right: 5px;
			font-size: 12px;
			position: relative;
			bottom: 1px;

			&.icon-users { font-size: 14px; }
			&.icon-arrows { font-size: 14px; }
		}
	}
}



.dl {
	display: block;
	line-height: 1.2;

	dt, dd {
		display: block;
	}
	dt {
		color: $grey;
		font-size: 12px;
	}
	dd {
		font-size: 14px;
	}
	& + .dl {
		margin-top: 10px;
	}
}


/*large users list*/
.users-list {

	a {
		display: table;
		table-layout: fixed;
		border-collapse: collapse;
		width: 100%;
		font-size: 14px;
		color: $dark;

		> span {
			display: table-cell;
			vertical-align: middle;
			padding: 10px;
		}
		&:hover {
			background: $light;
		}
	}
	li + li {
		border-top: 1px solid $light;
	}
}
tags-select {
	display: block;
	width: 100%; height: auto;
	position: relative;
	user-select: none;
	z-index: 3;

	.anchor {
		padding: 0;
	}
	.btn-wrapper {
		padding: 10px 15px;
	}
	.options-list {

		li {
			display: block;
			padding: 5px 15px;
			cursor: pointer;

			img {
				display: inline-block;
				width: 20px; height: 20px;
				border-radius: 50%;
				vertical-align: middle;
				position: relative;
				margin-right: 10px;

				& ~ span {
					display: inline-block;
					vertical-align: middle;
					width: calc(100% - 35px);
					line-height: 1.2;
				}
			}
			&:hover {
				background: $light;
			}
			&.active {
				color: $blue;

				img {
					box-shadow: 0 0 0 2px $blue;
				}
			}
		}
	}
	.selected-list {
		white-space: nowrap;
		display: block;
		border-radius: 20px;
		overflow: hidden;
		width: calc(100% - 8px);
		margin: 4px;
		color: $dark;

		> li {
			display: inline-block;
			vertical-align: middle;
			line-height: 20px;
			background: $light;
			border-radius: 10px;
			padding: 0 5px;
			margin-right: 4px;
			max-width: 100px;
			overflow: hidden;
			position: relative;
			transition: .1s;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			&:hover {
				background: $blue;
				color: #fff;

				.remove {
					display: block;
					color: #fff;
					background: $blue;
					box-shadow: 0 0 10px 10px $blue;

					&:hover {
						color: $blue;
						background: #fff;
					}
				}
			}
			img {
				display: inline-block;
				width: 20px; height: 20px;
				border-radius: 50%;
				vertical-align: middle;
				position: relative;
				margin-right: 5px;
				bottom: 1px;
				margin-left: -5px;

				& ~ span {
					display: inline-block;
					vertical-align: middle;
					width: calc(100% - 20px);
					line-height: 1.2;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			&.full-width {
				width: 100%;
				max-width: 100%;
				display: block;
				background: transparent;
				pointer-events: none;

				.remove {
					display: none;
				}
			}
			&.large {
				max-width: 150px;
			}
		}
		.remove {
			position: absolute;
			top: 0; right: 0;
			display: none;
			transition: .1s;
			border-radius: 50%;
			width: 20px; height: 20px;
			background: $grey;
			line-height: 20px;
			text-align: center;
			font-size: 10px;
			box-shadow: 0 0 5px 5px $light;
			cursor: pointer;

			.icon {
				display: block;
				line-height: inherit;
				width: inherit; height: inherit;
			}
		}
	}
	mark {
		display: inline;
		color: #fff;
		background: $blue;
	}

	.text {
		line-height: 20px;
		display: block;
		padding: 4px 15px;
	}

	&.open {
		z-index: 5;

		.dropdown-menu {
			display: block;
		}
	}
}
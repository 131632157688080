@import 'variables.scss';

.preloader {
	display: block;
	width: 100px;
	height: 3px;
	border-radius: 3px;
	background: rgba(0,0,0,.1);
	position: relative;
	overflow: hidden;
	display: none;

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		border-radius: inherit;
		background: $blue;
	}
	&.active {
		display: block;

		&:after {
			animation: preloader 1s infinite;
		}
	}
}
@keyframes preloader {
	0% { transform: translateX(-110%); }
	100% { transform: translateX(110%); }
}


.preloader.absolute {
	position: absolute;
	top: 50%; left: 50%;
	margin-top: -2px;
	margin-left: -50px;
}



/* progress */
.loading-progress {
	display: block;
	height: 3px;
	border-radius: 3px;
	overflow: hidden;
	display: none;
	background: $light;

	span {
		display: block;
		width: 0; height: 100%;
		background: $blue;
		position: relative;
	}
	&.active {
		display: block;

		span {
			transition: .2s;
		}
	}
	&.absolute {
		position: absolute;
		top: 0; left: 0;
		width: 100%;
		z-index: 5;
	}
}
@import 'variables.scss';

sidebar {
	color: $dark;
	display: block;
	position: fixed;
	z-index: 9;
	width: 315px;
	top: 60px; left: 0;
	height: calc(100vh - 60px);
	background: #fff;
	box-shadow: 0 0 20px rgba(0,0,0,.1);
	user-select: none;
	/*padding-right: 10px;*/

	.spaced-block {
		padding: 20px 30px;
		border-bottom: 1px solid $border-color;

		&.collapsed {
			padding: 0;
		}
	}
	/*user*/
	.person-block {

		.image-cover {
			width: 80%;
			padding-top: 80%;
			background: $light;
			border-radius: 50%;

			&:hover {
				box-shadow: 0 0 0 4px $blue;
			}
		}
		.name, .role {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.name {
			margin-bottom: 5px;
		}
		.role {
			font-size: 11px;
		}

		spoiler {

			.links-list {
				margin-left: -30px;
				margin-right: -30px;
				margin-bottom: -20px;

				a {
					padding: 5px 20px 5px 63px;
				}
			}
		}
	}

	.uah {
		color: $blue;
		font-size: 90%;
		font-weight: 500;
	}

	/*menu*/
	.menu {
		padding: 15px 0;

		> li {
			position: relative;

			> a {
				display: block;
				padding: 10px 50px 10px 30px;
				color: #999;

				.icon {
					display: inline-block;
					width: 20px; height: 20px;
					line-height: 20px;
					text-align: center;
					vertical-align: middle;
					position: relative;
					bottom: 2px;
					font-size: 16px;
					margin-right: 15px;
					color: lighten($blue, 30);
				}
				&:hover {
					color: $blue;

					.icon { color: inherit; }
				}
			}
			&.open {
				background: rgba($blue, .05);

				> a {
					color: $dark;

					.icon { color: $blue; }
				}
			}
			&.active {
				box-shadow: inset 5px 0 0 0 $blue;
				background: rgba($blue, .05);

				> a {
					color: $blue;

					.icon { color: inherit; }
				}
			}
		}

		.spoiler-toggler {
			@include clear();
			position: absolute;
			width: 30px; height: 30px;
			line-height: 30px;
			text-align: center;
			top: 5px; right: 20px;
			cursor: pointer;
			border-radius: 50%;
			z-index: 2;
			font-size: 10px;

			.icon {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				bottom: 0;
			}

			&:hover {
				background: rgba(0,0,0,.1);
			}
		}

		.submenu {
			padding: 0 50px 10px 40px;

			a {
				color: #999;
				display: block;
				padding: 10px 25px;

				&:hover {
					color: $blue;
				}
			}
			.active a {
				color: $blue;
			}
		}

		&.static {
			border-bottom: 1px solid $border-color;

			> li {

				a {
					color: inherit;

					.icon {
						color: inherit;
					}
				}
			}
		}
	}

}

sidebar.collapsed {
	width: 60px;

	.menu {
		padding: 0;

		> li {

			> a {
				padding: 15px 5px;
				text-align: center;
				font-size: 0;

				.icon {
					margin: 0;
				}
				.ignore-collapse {
					display: block;
					font-size: 12px;
				}
			}
		}
		.submenu, spoiler {
			display: none;
		}
	}
	.person-block {
		padding: 5px;

		.row {

			> * {
				flex-basis: 100%;
				max-width: 100%;
				width: 100%;

				&:nth-child(2) {
					display: none;
				}
			}
		}
		.image-cover {
			width: 100%;
			padding-top: 100%;
		}
		.links-list {
			padding: 0;
			margin: 5px -5px -5px;

			a {
				padding: 5px;
				text-align: center;
				font-size: 12px;

				.icon {
					display: block;
					margin: 0 auto;
				}
			}
		}
	}
}
@import 'variables.scss';

modal,
.dialog-window {
	display: none;
	background: rgba(lighten($blue, 53), .9);
	position: fixed;
	top: 0; left: 0;
	width: 100%; height: 100%;
	z-index: 10;
	overflow-y: auto;
	overflow-x: hidden;

	.modal-content {
		position: relative;
		max-width: 400px;
		background: #fff;
		margin: 10px auto;
		box-shadow: 0 10px 30px rgba(0,0,0,.2);
		min-height: 40px;
		border-radius: 5px;
		padding: 10px 20px 20px;
	}
	.modal-scroll {
		margin: 0 -20px;
		padding: 0 20px;
	}
	.main-title {
		margin-bottom: 10px;
	}
	.close {
		position: absolute;
		top: 7px; right: 7px;
	}

	&.open {
		display: block;
	}
	&.large {

		.modal-content {
			max-width: 750px;
		}
	}
	&.extra {

		.modal-content {
			max-width: 1400px;
		}
	}
	&.small {

		.modal-content {
			max-width: 240px;
		}
	}
}

.dialog-window {

	.message {
		border-bottom: 1px solid #eee;
		padding-top: 5px;
		padding-bottom: 15px;
		margin-bottom: 15px;
		font-size: 14px;
	}
}


modal .modal-scroll .users-mini-list {
	margin-left: -10px;
	margin-right: -10px;
}
@import 'variables.scss';

.rooms-list {
	font-size: 0;
	margin: -5px;

	> li {
		display: inline-block;
		vertical-align: middle;
		width: 33.3%;
		font-size: 14px;
		position: relative;
		border-radius: 30px;
		padding: 5px;

		.image-cover,
		.content {
			display: inline-block;
			vertical-align: middle;
		}
		.image-cover {
			width: 32px;
			padding-top: 32px;
			border-radius: 50%;
		}
		.content {
			width: calc(100% - 32px);
			padding: 0 10px;
			white-space: nowrap;

			.title {
				line-height: 1;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			small {
				color: $grey;
			}
		}
		.overlay {
			position: absolute;
			border-radius: 30px;
			top: 0; left: 0;
			width: 100%; height: 100%;
			z-index: 3;
			background: rgba(255,255,255,.8);
			padding: 10px 0;
			pointer-events: none;
			opacity: 0;
			text-align: center;
		}

		&:hover {
			box-shadow: 0 0 0 1px #eee;

			.overlay {
				pointer-events: auto;
				opacity: 1;
			}
		}
	}
}
@import 'variables.scss';

notificator {
	position: fixed;
	z-index: 100;
	top: 60px; right: 0;
	pointer-events: none;
	width: 300px;
	padding: 10px;
	user-select: none;

	.notification {
		background: #fff;
		border: 1px solid #eee;
		padding: 10px 15px;
		margin-bottom: 5px;
		pointer-events: auto;
		cursor: default;
		border-radius: 2px;
		transition: .1s;

		.title {
			font-size: 16px;
			line-height: 1;
			margin-bottom: 5px;
		}
		.text {
			font-size: 13px;
			line-height: 1.2;
		}

		&:hover {
			border-color: $blue;
		}
		&.not-active {
			opacity: 0;
			transform: scale(.9);
			pointer-events: none;
		}
		&.error {
			background: lighten($red, 45);
			border-color: rgba($red, .1);
			color: $red;

			&:hover {
				border-color: $red;
			}
		}
		&.success {
			background: lighten($green, 48);
			border-color: rgba($green, .1);
			color: $green;

			&:hover {
				border-color: $green;
			}
		}
	}
}
@import 'variables.scss';

.tabs {
	display: block;
	font-size: 0;
	user-select: none;
	margin: -10px -10px 0;
	padding: 10px 10px 0;
	overflow: hidden;

	> * {
		display: inline-block;
		vertical-align: bottom;
		white-space: nowrap;
		max-width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 20px;
		font-size: 13px;
		background: darken($light, 5);
		border-radius: 7px 7px 0 0;
		cursor: pointer;
		color: $dark;
		line-height: 30px;
		height: 30px;
		text-decoration: none;
		box-shadow: 0 0 5px rgba(0,0,0,.1);

		&:hover,
		&:focus {
			background: darken($light, 10);
			color: $dark;
			position: relative;
			z-index: 2;
		}
		&.active {
			color: $blue;
			position: relative;
			z-index: 3;
			background: #fff;
			box-shadow: 0 0 15px rgba(0,0,0,.1);
		}
	}
}
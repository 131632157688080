@import 'variables.scss';

$h: 15px;
$light: #f7f7f7;

schedule {
	background: #fff;
	display: block;
	min-height: 10px;
	box-shadow: 0 2px 10px rgba(0,0,0,.1);
	border-radius: 5px;
	font-size: 0;
	white-space: nowrap;

	.wrapper {
		position: relative;
	}

	.timeline,
	.rooms {
		display: inline-block;
		vertical-align: top;
		height: 100%;

		.header {
			border-bottom: 1px solid $border-color;
			white-space: nowrap;
			overflow: hidden;
			padding: 15px 10px;
			line-height: 20px;
			text-align: center;
			font-size: 15px;
			text-overflow: ellipsis;

			span {
				color: $grey;
			}
		}
	}
	.timeline {
		width: 80px;
	}

	.hours {

		> li {
			display: block;
			box-shadow: inset 0 -1px 0 0 $border-color;

			> div {
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;

				&:nth-child(1){
					width: calc(100% - 25px);
					padding: 10px;
				}
				&:nth-child(2){
					width: 25px;
					box-shadow: inset 1px 0 0 0 $border-color;
				}

				span {
					font-size: 11px;
					display: block;
					line-height: $h;
					height: $h;
					text-align: center;
					box-shadow: inset 0 -1px 0 0 $border-color;

					&:nth-child(even){
						background: $light;
					}
				}
			}
		}
		&.collapsed {
			$h: 8px;
			position: relative;

			> li {
				height: $h;
				box-shadow: none;
				border: none;

				> div,
				span {
					display: none;
				}
				&:first-child,
				&:last-child {

					> div {
						position: absolute;
						width: 100%;
						display: block;
						padding: 0;
						text-align: center;
					}
				}
				&:first-child {

					> div {
						top: 5px;
					}
				}
				&:last-child {

					> div {
						bottom: 5px;
					}
				}
			}
		}
	}
	.rooms {
		border-left: 1px solid $border-color;
		width: calc(100% - 80px);
		font-size: 14px;

	}
	.lessons {
		height: calc(100% - 50px);
		position: relative;
		background: repeating-linear-gradient(
			to bottom,
			darken($border-color, 5) 0px,
			darken($border-color, 5) 1px,
			transparent 1px,
			transparent $h*4
			), repeating-linear-gradient(
			to bottom,
			transparent,
			transparent $h,
			lighten($border-color, 2) $h,
			lighten($border-color, 2) $h*2
			);
			&.collapsed {
				$h: 104px;
				background: repeating-linear-gradient(
					to bottom,
					transparent,
					transparent $h,
					$border-color $h,
					$border-color $h*2
					);
			}
		}
		.room {
			display: inline-block;
			vertical-align: top;
			width: 10%;
			height: 100%;

			&:not(:first-child){
				border-left: 1px solid $border-color;
			}

			&.disabled {
				pointer-events: none;
				opacity: .5;
			}
		}

		.day-time.collapsed:nth-child(odd){
			background: $light;
		}
		.day-time.collapsed {

			.date-title {
				height: 1px;

				&:before {
					left: 65px;
					border-radius: 50%;
					width: 30px;
					font-size: 14px;
					text-overflow: initial;
					margin-left: 0;
					line-height: 30px;
					margin-top: -15px;
					line-height: 30px;
				}
				&:after {
					content: attr(data-day);
					position: absolute;
					z-index: 2;
					top: 30px; left: 0;
					width: 80px; line-height: 40px;
					height: 40px;
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 30px;
					color: rgba(0,0,0,.1);
				}
			}
		}


		.date-title {
			height: 2px;
			width: 100%; left: 0;
			margin-top: -1px;
			background: #fff;
			z-index: 2;
			position: absolute;
			box-shadow: 0 5px 10px rgba(0,0,0,.2);
			color: $dark;

			&:before {
				content: attr(data-title);
				background: inherit;
				box-shadow: 0 5px 10px rgba(0,0,0,.1);
				text-align: center;
				font-size: 13px;
				line-height: 20px;
				white-space: nowrap;
				font-weight: 700;
				width: 240px;
				left: 50%;
				position: absolute;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: -10px -120px;
				border-radius: 10px;
				margin-top: -10px;
			}
			&.holiday {
				background: $red;
				color: #fff;
			}
		}
	}

	lesson {
		display: block;
		width: 100%; min-height: $h;
		z-index: 2;
		font-size: 11px;
		line-height: 1;
		background: #fff;
		/*background: lighten($blue, 52);*/
		position: absolute;
		box-shadow: 0 0 0 1px rgba(#000,.06);
		/*box-shadow: 0 0 0 1px rgba($blue,.1), inset 3px 0 0 0 $blue;*/
		/*box-shadow: inset 0 0 0 1px rgba(#000,.5), inset 3px 0 0 0 $blue;*/
		padding: 2px 5px 2px calc(#{$h} + 10px);
		cursor: pointer;
		transition: .1s;

		.counter,
		.cycle {
			position: absolute;
			display: block;
			width: $h; height: $h;
			left: 5px; top: 0;
			text-align: center;
			line-height: $h;
			color: $blue;
			font-size: 11px;

			span {
				display: block;
				font-size: 9px;
				line-height: 1;
				position: absolute;
				width: 12px; height: 12px;
				line-height: 12px;
				text-align: right;
				bottom: 30%; right: -3px;
			}
		}
		.cycle {
			top: $h;
			font-size: 8px;
			color: $green;
		}
		.clients {
			overflow: hidden;
			white-space: normal;
			max-height: calc(100% - 14px);
			position: relative;

			> li {
				display: inline-block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 50px;
			}
			&:not(.flat):after {
				content: '';
				display: block;
				width: 100%; height: 10px;
				background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				z-index: 2;
				bottom: -1px; left: 0;
				position: absolute;
			}

			&.flat {
				white-space: nowrap;
			}
		}
		.type {
			display: block;
			position: absolute;
			left: calc(#{$h} + 10px);
			bottom: 2px; right: 45%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: rgba(0,0,0,.4);
		}
		.time {
			position: absolute;
			right: 5px; left: 50%;
			bottom: 2px;
			text-align: right;
			color: $green;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.icon {
				font-size: 9px;
				display: inline-block;
				vertical-align: middle;
				position: relative;
				bottom: 1px;
				color: $grey;
			}
		}
		.line {
			position: absolute;
			width: 3px; height: 100%;
			top: 0; left: 0;
			z-index: 2;
			transition: .1s;
		}
		.fader {
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			opacity: .0;
		}

		&:hover {
			z-index: 3;
			background: #fff;
			/*box-shadow: 0 0 0 5px #fff, 0 0 0 6px rgba(0,0,0,.1), 0 0 20px rgba(0,0,0,.1);*/
			box-shadow: 0 0 0 1px rgba(0,0,0,.3);

			.line {
				/*transform: translateX(-5px);*/
				/*top: -5px; height: calc(100% + 10px);*/
			}
		}
		&.collapsed {
			min-height: 2px;
			padding: 0;
			box-shadow: none;

			.line {
				width: 100%;
			}
			.counter, .cycle, .clients, .type, .time {
				display: none;
			}
		}
	}

	.lesson-details {
		position: absolute;
		font-size: 13px;
		white-space: normal;
		/*transition: .1s;*/
		width: 300px; height: auto;
		z-index: 5;
		background: #fff;
		pointer-events: none;
		opacity: 0;
		/*transform: translateY(-100%);*/
		/*padding: 5px 10px;*/
		/*margin-top: 20px;*/
		margin-left: -150px;
		/*border: 1px solid $blue;*/
		border: 1px solid rgba(0,0,0,.2);

		&.active {
			/*transition: .1s;*/
			opacity: 1;
		}

		[data-info] {
			font-size: 12px;
			line-height: 1;
			margin: 5px 0;

			.icon {
				position: relative;
				color: $grey;
				bottom: 1px;
			}
			.icon-angle-right {
				width: 12px; height: 12px;
				line-height: 12px;
				font-size: 8px;
			}
		}
		.heading {
			/*box-shadow: inset 0 -2px 0 0 $blue;*/
			background: lighten($blue, 50);
			padding: 5px 10px;
			margin-bottom: 5px;

			.h3-title {
				color: $blue;

				&:first-child {
					color: $dark;
				}
			}
		}
		.darken {
			background: #fff;
			/*margin: 0 -10px -5px;*/
			padding: 5px 10px;
		}
		.users-mini-list {
			padding: 0;
			margin: 5px 0 5px ;
			text-align: left;
			font-size: 0;

			> li {
				padding: 0;
				color: $dark;
				display: inline-block;
				width: 50%; height: auto;
				vertical-align: middle;
				font-size: 12px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: left;
				line-height: 1;
				padding-top: 5px;

				img {
					display: inline-block;
					bottom: 0;
					margin: 0 auto;
					width: 20px; height: 20px;
				}
			}
		}

	}


	schedule-page .schedule-title {
		font-size: 16px;
	}


	schedule-page .fixed-header {
		position: fixed;
		top: 60px; left: 335px;
		right: 15px; height: auto;
		padding-left: 80px;
		z-index: 6;
		font-size: 0;
		background: #fff;
		box-shadow: 0 5px 10px rgba(0,0,0,.1);
		border-radius: 0;
		pointer-events: none;
		transition: .2s;
		opacity: 0;
		transform: translateY(-100%);

		> div {
			display: inline-block;
			vertical-align: middle;
			width: calc(10% - 8px);
			font-size: 14px;
			line-height: 30px;
			text-align: center;
			border-left: 1px solid $light;
			padding: 0 5px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:first-child {
				width: 80px;
				border: none;
			}
			&.disabled {
				opacity: .5;
			}
		}
		&.active {
			opacity: 1;
			transform: translateY(0);
		}
	}

	sidebar.collapsed ~ .page-content schedule-page .fixed-header {
		left: 75px;
	}





/* -----------------------------------------------------------------------
TEACHERS SCHEDULE
----------------------------------------------------------------------- */

.teachers-schedule {
	display: flex;
	align-items: stretch;

	$teachers_width: 200px;
	$m: 15px;

	.teachers-list,
	.schedule-list {

	}
	.teachers-list {
		width: $teachers_width;
		flex-basis: $teachers_width;
		position: relative;
		z-index: 5;
		border-right: 1px solid $border-color;
	}
	.schedule-list {
		width: calc(100% - #{$teachers_width});
		flex-basis: calc(100% - #{$teachers_width});
		overflow-x: scroll;
	}
	.schedule-list {

		.time {
			white-space: nowrap;
			height: 41px;
			border-bottom: 1px solid $border-color;
			position: relative;

			> li {
				display: inline-block;
				width: 200px;
				text-align: center;
				font-size: 13px;
				border-right: 1px solid $border-color;
			}
			.hour {
				border-bottom: 1px solid $border-color;
				line-height: 25px;
				height: 25px;
			}
			.minutes {
				line-height: 15px;
				height: 15px;
				display: table;
				width: 100%;
				table-layout: fixed;
				border-collapse: collapse;
				font-size: 11px;
				z-index: 3;
				box-shadow: 0 2px 4px rgba(0,0,0,.1);

				span {
					display: table-cell;
					vertical-align: middle;
					border-left: 1px solid $border-color;
					border-right: 1px solid $border-color;

					&:nth-child(2), &:nth-child(4) { background: $light; }
					&:first-child { border-left: none; }
					&:last-child { border-right: none; }
				}
			}
		}
	}
	.teachers {
		padding: 40px 0;

		> li {
			border-top: 1px solid $border-color;
		}
		.days {
			text-align: center;
			box-shadow: 4px 0 4px rgba(0,0,0,.1);
			position: relative;
			z-index: 3;

			li {
				line-height: 12px;
				font-size: 10px;
				padding-top: 8px;
				height: 40px;

				&:nth-child(odd) {
					background: $light;
				}
				&:nth-last-child(1),
				&.holiday {
					color: $red;
					background: lighten($red, 40);
				}
				&.today {
					background: $green;
					color: #fff;
					position: relative;
					z-index: 4;

					&:after {
						content: '';
						display: block;
						border: 20px solid transparent;
						border-left: 10px solid $green;
						position: absolute;
						top: 0; left: 99.9%;
					}
				}
			}
		}
	}
	.teacher {
		padding-bottom: $m;
		position: relative;
		padding-right: 50px;
		height: 280px;

		.image-cover {
			margin: $m $m 0;
			width: calc(100% - #{$m * 2});
			padding-top: calc(100% - #{$m * 2});
			background: $light;
			border-radius: 5px;
		}
		.name {
			font-size: 14px;
			padding: 0 $m 0;
			margin: 10px 0;
			line-height: 1.2;
		}
		.info {
			font-size: 12px;
			padding: 5px $m;
			height: 60px;
			overflow: hidden;
		}
		tabs {
			display: block;
			width: 100%;

			ul {
				white-space: nowrap;
				padding-left: $m;
				padding-right: $m;
				font-size: 0;
				background: $blue;
				color: #fff;

				li {
					width: 25%;
					font-size: 12px;
					display: inline-block;
					height: 20px;
					line-height: 20px;
					text-align: center;
					vertical-align: middle;
					cursor: pointer;

					.icon {
						font-size: 12px;
						position: relative;
						bottom: 1px;
					}
					&:hover {
						background: lighten($blue, 20);
					}
					&.active {
						background: #fff;
						color: $blue;
						position: relative;
						box-shadow: 0 3px 10px rgba(0,0,0,.2);

						&:after {
							content: '';
							display: block;
							width: 7px; height: 7px;
							background: inherit;
							position: absolute;
							top: 100%; left: 50%;
							margin-top: -4px; margin-left: -4px;
							transform: rotate(45deg);
						}
					}
				}
			}
		}
		.days {
			position: absolute;
			top: 0; right: 0;
			height: 100%;
			width: 50px;
			border-left: 1px solid $border-color;
		}
	}
	.day-list {
		height: 280px;
		background: $light;

		> li {
			height: 40px;
			width: 100%;
			position: relative;
			border-bottom: 1px solid #fff;

			&.holiday {
				background: rgba(200,0,0,.1);
				z-index: 4;
			}
			&.today {
				background: rgba(0,250,0,.1);
				z-index: 4;
			}
		}
	}

	/* actions */
	.day-list {

		.occupation,
		.free-time,
		.not-working-time,
		.teacher-activity {
			position: absolute;
			top: 0;
			box-sizing: border-box;
		}

		.free-time,
		.not-working-time,
		.day-off,
		.teacher-activity {
			padding: 2px 5px 2px 10px;
			width: 0; height: 100%;
			font-size: 12px;
			line-height: 1;
			padding: 7px 10px;

			> div {
				padding: 0 10px;
				border-radius: 15px;
				background: rgba(255,255,255,.8);
			}
			.icon {
				width: 24px; height: 24px;
				line-height: 24px;
				text-align: center;
				position: absolute;
				top: 50%;
				background: $green;
				color: #fff;
				font-size: 14px;
				margin-top: -12px;
				border-radius: 50%;
				display: none;

				&.icon-clock { left: 5px }
				&.icon-plus { right: 5px }
			}
			.controls {
				position: absolute;
				top: 0; right: 20px;
				z-index: 2;
				padding: 5px;

				.btn {
					width: 24px;
				}
				.icon {
					background: transparent !important;
				}
			}
			&.meeting .controls .icon ,
			&.teacher_council .controls .icon { color: $ocean !important; }
			&.dinner .controls .icon { color: mix($yellow, $dark) !important; }
			&.sick .controls .icon { color: darken($yellow, 20) !important; }
			&.business_trip .controls .icon { color: mix($purple, $blue) !important; }
			&.vacation .controls .icon { color: $purple !important; }
			&.day_off .controls .icon { color: lighten($red, 40); color: $red !important; }
			&.small {
				font-size: 10px;

				> div {
					padding: 0;
					background: transparent;
				}
			}
			&.large {
				text-align: center;
				.icon { display: block; }
			}
			&.extralarge {

				> div {
					text-align: left;
					height: 26px;
					line-height: 26px;
					position: relative;
					padding-left: 30px;

					.timeline {
						display: block;
						position: absolute;
						top: 50%;
						margin-top: -1px;
						left: 230px; right: 30px;
						height: 2px;
						background: $green;

						&:before, &:after {
							content: '';
							display: block;
							width: 6px; height: 6px;
							background: inherit;
							border-radius: 50%;
							position: absolute;
							top: 50%;
							margin-top: -3px;
						}
						&:before { left: -3px; }
						&:after { right: -3px; }
					}
				}
			}
		}

		.teacher-activity {
			z-index: 3;

			> div {
				padding-left: 30px;
				white-space: nowrap;
				overflow: hidden;
			}
			.icon {
				display: block;
				left: 5px;
			}
			&.meeting .icon,
			&.teacher_council .icon { background: $ocean; }
			&.dinner .icon { background: mix($yellow, $dark); }
			&.sick .icon { background: $yellow; }
			&.business_trip .icon { background: mix($purple, $blue); }
			&.vacation .icon { background: $purple; }
			&.day_off .icon { background: lighten($red, 20); color: #fff; }
			&.meeting,
			&.teacher_council { color: $ocean; }
			&.dinner { color: mix($yellow, $dark); }
			&.sick { color: darken($yellow, 20); }
			&.business_trip { color: mix($purple, $blue); }
			&.vacation { color: $purple; }
			&.day_off { color: lighten($red, 40); color: $red; }
		}

		.occupation {
			padding: 2px 5px 2px 10px; width: 0; height: 100%;
			background: #fff;
			box-shadow: inset 3px 0 0 0 $blue;
			z-index: 4;
			overflow: hidden;

			.time,
			.title,
			.room {
				white-space: nowrap;
				line-height: 12px;
				font-size: 12px;
				overflow: hidden;
			}
			.title { color: $grey; }
			.time {
				text-align: right;
				color: $green;
			}
		}
		.free-time {
			background: lighten($green, 40);
			z-index: 2;
			color: $green;
		}
		.not-working-time {
			background: $light;
			position: absolute;
			height: 100%;
			z-index: 1;
			color: $grey;

			div {
				border-radius: 0;
			}
			.icon {
				background: rgba(0,0,0,.1);
			}
			.timeline {
				background: $light !important;
			}
		}
		.day-off {
			background-color: lighten($red, 40);
			color: $red;
			width: 100%;

			.inner {
				height: 26px; width: 100%;
				line-height: 26px;
				padding: 0 30px;
				background-color: lighten($red, 48);
			}
			.icon {
				display: block;
				background-color: $red;
			}
		}

		.meeting, .teacher_council { background: mix($ocean, #fff); }
		.dinner { background: mix(mix($yellow, $dark), #fff); }
		.sick { background: mix($yellow, #fff); }
		.business_trip { background: mix(mix($purple, $blue), #fff); }
		.vacation { background: mix($purple, #fff); }
		.day_off { background: lighten($red, 40); color: $red; }
	}
}

.action-type-list {
	font-size: 0;
	margin: 0 -2px;

	.item {
		display: inline-block;
		vertical-align: top;
		padding: 0 2px;
	}

	.teacher-action-type {
		display: inline-block;
		color: $dark;
		height: 20px;
		border-radius: 12px;
		font-size: 12px;

		&.not-working { background: $light; box-shadow: inset 0 0 0 1px rgba(0,0,0,.2); color: $grey; }
		&.occupation { background: #fff; box-shadow: inset 0 0 0 1px $blue; }
		&.free { background: lighten($green, 30); }
		&.meeting { background: $ocean; color: #fff; }
		&.dinner { background: mix($yellow, $dark); color: #fff; }
		&.healthcare { background: $yellow; }
		&.trip { background: mix($purple, $blue); color: #fff; }
		&.vacation { background: $purple; color: #fff; }
		&.weekend { background: lighten($red, 40); color: $red; }

		.btn {
			background: inherit;
			color: inherit;
		}
		.text {
			padding: 0 7px;
			line-height: 1.7;
			display: inline-block;
			vertical-align: middle;
		}
	}
}

@import 'variables.scss';

.alert {
	background: $light;
	/*border: 1px solid darken($light, 5);*/
	padding: 10px 15px;
	border-radius: 3px;
	color: $grey;
	font-size: 12px;

	&.spaced {
		margin: 20px;
	}
	&.empty {
		margin: 0 -10px;
		padding: 50px 10px;
	}

	&.primary {
		background: lighten($blue, 50);
		color: $blue;
	}
	&.success {
		background: lighten($green, 47);
		color: $green;
	}
	&.error {
		background: lighten($red, 45);
		color: $red;
	}
	&.bordered {
		border: 1px solid rgba(0,0,0,.1);
	}
	&.transparent {
		background: transparent;
		border: none;
	}
}
@import 'variables.scss';

main-header {
	color: $dark;
	display: block;
	position: fixed;
	top: 0; left: 0;
	z-index: 10;
	width: 100%;
	height: 60px;
	background: #fff;
	box-shadow: 0 0 20px rgba(0,0,0,.1);
	user-select: none;
	padding-right: 10px;

	.layout {
		display: table;
		table-layout: fixed;
		width: 100%;

		.column {
			display: table-cell;
			vertical-align: top;
			position: relative;
			height: 100%;
			padding: 0 5px;

			&.logo-column {
				width: 320px;
			}
			&.search-column,
			&.location-column {
				width: auto;
				padding-top: 15px;
				padding-right: 40px;
			}
			&.logout-column {
				width: 190px;
				padding-top: 15px;
			}
			&.birthdays-column,
			&.debt-column,
			&.tasks-column,
			&.queue-column,
			&.task-column {
				width: 160px;
				padding-top: 15px;
			}
			&.birthdays-column { width: 170px; }
			&.debt-column { width: 160px; }
			&.tasks-column, &.queue-column { width: 150px; }
		}
	}

	.logo {
		display: block;
		width: 100%; height: 60px;
		position: relative;
		padding: 15px;

		img {
			display: block;
			width: 211px; height: 30px;
		}
		&:after {
			content: '';
			display: block;
			width: 10px; height: 100%;
			position: absolute;
			top: 0; left: 100%;
			z-index: 2;
			background: linear-gradient(to right, rgba(0,0,0,.07) 0%, rgba(0,0,0,0) 100%);
		}
		&:hover {
			background: $light;
		}
	}

	.menu-toggle {
		position: absolute;
		top: 0; right: 5px;
		z-index: 3;
		width: 60px; height: 60px;
		padding: 0 15px;
		cursor: pointer;
		border: none;
		border-radius: 0;
		background: transparent;
		transition: .1s;

		i {
			display: block;
			width: calc(70% - 2px); height: 2px;
			background: $blue;
			margin: 7px 0 7px auto;
			position: relative;
			transition: .1s;

			&:before {
				content: '';
				display: block;
				position: absolute;
				right: 100%; width: 30%; height: 100%;
				background: $blue;
				margin-right: 2px;
				transition: .1s;
			}
			&:nth-child(3){

				&:before {
					display: none;
				}
			}
		}
		&:hover {
			background: $blue;

			i {
				background: #fff;

				&:before {
					background: #fff;
				}
			}
		}
	}
}

@media (max-width: 1700px){

	main-header {

		.layout {

			.column {

				&.birthdays-column,
				&.debt-column,
				&.tasks-column,
				&.queue-column {
					width: 70px;
				}
			}
		}
	}
}

@media (max-width: 1440px){

	main-header {

		.layout {

			.column {

				&.search-column,
				&.location-column {
					padding-right: 5px;
				}
			}
		}
	}
}

@media (max-width: 1200px){

	main-header {

		.layout {

			.column {

				&.logout-column {
					width: 40px;
				}
			}
		}
	}
}
@import 'variables.scss';

.btn {
	display: inline-block;
	width: auto;
	vertical-align: middle;
	height: 30px;
	border-radius: 20px;
	border: none;
	background: #fff;
	transition: .1s;
	font-family: $font;
	font-size: 14px;
	line-height: 20px;
	padding: 5px 15px;
	color: $color;
	text-align: center;
	box-shadow: 0 2px 10px rgba(0,0,0,.1);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	cursor: pointer;
	position: relative;

	.icon {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		bottom: 1px;
		width: 20px; height: 20px;
		line-height: 20px;
		text-align: center;
		font-size: 14px;

		&.primary { color: $blue; }
		&.error { color: $red; }
		&.success { color: $green; }
		&.faded {
			opacity: .5;
		}
		&.success { color: $green; }
		&.error { color: $red; }
		&.primary { color: $blue; }
		&.small {
			width: 14px; height: 14px;
			line-height: 14px;
			font-size: 10px;
			bottom: 0;
		}
		&.offset-right { margin-right: 10px; }
		&.offset-left { margin-left: 10px; }
	}

	&:hover {
		color: $dark;
		box-shadow: 0 3px 15px rgba(0,0,0,.2);
	}
	&:focus {
		color: $dark;
		box-shadow: 0 0 0 3px $focus;
	}
}

.btn.btn-block {
	width: 100%;
	padding-left: 5px;
	padding-right: 5px;
}

.btn.btn-transparent {
	background: transparent;
	box-shadow: none;

	&:hover {
		color: darken($dark, 10);
		box-shadow: none;
	}
	&:focus {
		/*box-shadow: 0 0 0 3px $focus;*/
	}
}

.btn {

	.icon-drop-down {
		font-size: 8px;
		width: 10px; height: 10px;
		line-height: 10px;
	}
	&.disabled {
		box-shadow: none;
		pointer-events: none;
	}
}

.btn.btn-primary,
.btn.btn-success,
.btn.btn-error {
	color: #fff;
}

.btn.btn-primary {
	background: $blue;

/*	&:focus {
		box-shadow: 0 0 0 3px rgba($blue, .3);
	}*/
}

.btn.btn-success {
	background: $green;

/*	&:focus {
		box-shadow: 0 0 0 3px rgba($green, .3);
	}*/
}

.btn.btn-error {
	background: $red;

/*	&:focus {
		box-shadow: 0 0 0 3px rgba($red, .3);
	}*/
}

.btn {

	.notifications {
		display: inline-block;
		width: 14px; height: 14px;
		line-height: 14px;
		text-align: center;
		font-size: 9px;
		vertical-align: middle;
		position: relative;
		z-index: 2;
		color: $grey;

		&:before {
			content: "\e932";
			font-family: 'icomoon';
			display: block;
		}
		.counter {
			position: absolute;
			z-index: 2;
			width: 14px; height: 14px;
			border-radius: 50%;
			background: $red;
			color: #fff;
			bottom: 50%; left: 50%;
			overflow: hidden;
			white-space: nowrap;
			margin-left: -3px;
			margin-bottom: -1px;
		}
	}
}

.btn-wrapper {
	padding: 10px;

	.btn + .btn {
		margin-left: 10px;
	}
}

.btn-group {

	.btn + .btn {
		margin-left: 10px;
	}
}

.btn.btn-small {
	height: 20px;
	font-size: 12px;
	line-height: 16px;
	padding: 2px 10px;

	.icon {
		bottom: 1px;
	}
	.icon.offset-right { margin-right: 5px; }
	.icon.offset-left { margin-left: 5px; }
}
.btn.btn-large {
	height: 40px;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 25px;

	.icon {
		font-size: 16px;
		bottom: 1px;
	}
	.icon.offset-right { margin-right: 10px; }
	.icon.offset-left { margin-left: 10px; }
}


.btn.btn-round {
	width: 30px;
	padding-left: 0;
	padding-right: 0;
	font-size: 11px;

	&.btn-small {
		width: 20px; height: 20px;
		padding: 0;
		line-height: 20px;
	}
}

[data-add-button] {
	position: absolute;
	bottom: 0; left: 0;
}
dropdown {
	display: block;
	position: relative;
	width: 100%;

	&.inline {
		display: inline-block;
		vertical-align: middle;
		width: auto;
		max-width: 100%;

		.dropdown-menu {
			left: 0;
		}
	}
	&.large {

		.dropdown-menu {
			width: 480px;
		}
		&.inline .dropdown-menu { margin-left: -240px; }
	}
	&.big {

		.dropdown-menu {
			width: 320px;
		}
		&.inline .dropdown-menu { margin-left: -160px; }
	}
	&.medium {

		.dropdown-menu {
			width: 240px;
		}
		&.inline .dropdown-menu { margin-left: -120px; }
	}
	&.small {

		.dropdown-menu {
			width: 160px;
		}
		&.inline .dropdown-menu { margin-left: -70px; }
		&[data-origin="right"] {

			.dropdown-menu {
				left: auto; right: 50%;
				margin-right: -30px;

				&:before {
					left: auto; right: 22px;
				}
			}
		}
	}
	&[data-origin="right"] {

		.dropdown-menu {
			left: auto; right: 50%;
			margin-right: -100px;

			&:before {
				left: auto; right: 90px;
			}
		}
	}
	&.fit {

		.dropdown-menu {
			left: 0; width: 100%;
			margin-left: 0;
		}
	}
	&.open {
		z-index: 5;

		.dropdown-menu {
			display: block;
		}
	}
}

.links-list {
	padding: 10px 0;

	a,
	.link {
		display: block;
		color: $color;
		line-height: 1.2;
		padding: 5px 15px;
		cursor: pointer;

		.icon {
			display: inline-block;
			vertical-align: middle;
			width: 20px; height: 20px;
			line-height: 20px;
			text-align: center;
			bottom: 2px;
			margin: -4px 0;
			margin-right: 10px;
		}

		&:hover {
			background: $light;
		}
		&.active {
			color: $dark;
		}
	}
}

.dropdown-menu {
	font-size: 13px;
	position: absolute;
	background: #fff;
	display: none;
	z-index: 2;
	top: 100%; left: 0;
	box-shadow: 0 5px 30px rgba(0,0,0,.2);
	border-radius: 5px;
	width: 100%;
	margin-top: 15px;
	text-align: left;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 14px; height: 14px;
		background: #fff;
		z-index: -1;
		top: -7px; left: 50%;
		margin-left: -7px;
		transform: rotate(45deg);
		box-shadow: 0 0 20px rgba(0,0,0,.1);
	}
	.content {
		border-radius: 5px;
		background: #fff;
		min-height: 20px;
		padding: 1px 0;
	}
	.scrolled {
		overflow: hidden;
		overflow-y: auto;
		max-height: 400px;
	}
}
@import 'variables.scss';

.input,
tags-select .anchor {
	display: block;
	width: 100%;
	height: 30px;
	border-radius: 15px;
	border: 1px solid $border;
	background: #fff;
	transition: .1s;
	font-family: $font;
	font-size: 13px;
	line-height: 18px;
	padding: 4px 15px;
	color: $color;
	@include placeholder(#bbb);

	&:hover {
		border: 1px solid darken($border, 10);
	}
	&:focus {
		border-color: transparent;
		box-shadow: 0 0 0 4px $focus;
		position: relative;
		z-index: 2;
	}

	&.grey {
		border-color: transparent;
		background: $light;

		&:hover {
			background: darken($light, 5);
		}
		&:focus {
			background: #fff;
			box-shadow: 0 0 0 3px $focus;
		}
	}
	&.transparent {
		background: transparent;
		border-color: transparent;
		padding-left: 0;
		padding-right: 0;

		&:hover {
			background: transparent;
			border-color: transparent;
		}
		&:focus {
			background: transparent;
			border-color: transparent;
			box-shadow: none;
		}
	}
}

span.highlight {
	background: $blue;
	color: #fff;
}

select.input {
	-webkit-appearance: none;
}

tr.highlighted .input {
	border-color: lighten($blue, 40);
}

label,
.label {
	display: block;
	margin-top: 5px;
	font-size: 12px;
	color: #999;
	cursor: pointer;

	&:hover {
		color: $dark;
	}
}
.form-group {
	position: relative;

	.input-icon,
	.input-suffix {
		position: absolute;
		z-index: 2;
		pointer-events: none;
		color: $color;
		font-size: 10px;
		top: 0; right: 0;
		width: 30px; height: 30px;
		line-height: 30px;
		text-align: center;

		& ~ .input {
			padding-right: 25px;
		}
		&.small {
			top: -3px;
		}
	}

	label ~ .input-icon {
		top: 18px;
	}

	.input-suffix {
		color: $grey;

		& ~ .input {
			padding-left: 5px;
			padding-right: 10px;
		}
		&.small {
			top: -1px;
			right: -3px;
		}
	}

	&.controls-group {

		.input {
			display: inline-block;
			vertical-align: middle;
			width: calc(100% - 70px);
			margin-right: 5px;
		}
		.btn {
			vertical-align: middle;
		}
	}
	&.collapsed {
		margin: 0;
	}
}

.input.small,
tags-select .anchor.small {
	height: 24px;
	line-height: 16px;
	padding: 4px 15px;

	&.paddless {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.form-group {
	margin-bottom: 10px;
	position: relative;

	[type="file"] {
		display: none;
	}
	[data-validation] {
		position: absolute;
		top: 0; right: 0;
		width: 30px; height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 50%;
		font-size: 10px;
		z-index: 2;
		pointer-events: none;
		opacity: 0;

		&[class*="checked"] { color: $green; }
		&[class*="cross"] { color: $red; }
		&.active {
			opacity: 1;
		}
	}

	.input:focus ~ .tooltip {
		transform: scale(1);
		opacity: 1;
	}

	&.error {

		.input,
		tags-select .anchor {
			border-color: rgba($red, .3);
			color: $red;
			@include placeholder(rgba($red, .5));

			&:focus {
				box-shadow: 0 0 0 3px rgba($red, .3);
			}
		}

		[class*="cross"] { opacity: 1; }
	}
	&.success {

		.input,
		tags-select .anchor {
			border-color: rgba($green, .3);
			color: $green;
			@include placeholder($green);

			&:focus {
				box-shadow: 0 0 0 3px rgba($green, .3);
			}
		}

		[class*="checked"] { opacity: 1; }
	}
}


.static-form {
	display: block;
	margin: 0 auto;
	border-radius: 5px;

	&.small {
		max-width: 220px;
	}
}

.tooltip {
	position: absolute;
	bottom: 100%; left: 50%;
	width: 240px;
	margin-left: -120px;
	z-index: 2;
	font-size: 12px;
	line-height: 1.2;
	background: $dark;
	color: rgba(255,255,255,.6);
	border-radius: 5px;
	padding: 5px 10px;
	margin-bottom: 10px;
	pointer-events: none;
	transform-origin: bottom;
	transform: scale(.9);
	opacity: 0;
	transition: .1s;

	&:before {
		content: '';
		display: block;
		width: 6px; height: 6px;
		background: inherit;
		position: absolute;
		top: 100%; left: 50%;
		margin: -3px;
		transform: rotate(45deg);
	}

	&.disabled {
		transform: scale(.9) !important;
		opacity: 0 !important;
	}
}


.input-sublink {
	position: relative;
	text-align: right;
	margin-top: -5px;
	margin-bottom: 10px;

	a {
		display: inline-block;
		font-size: 12px;
	}
}


.input.inline {
	display: inline-block;
	vertical-align: middle;
	padding-left: 5px;
	padding-right: 5px;
}

.input-caption {
	padding: 0 10px;
	font-size: 12px;
	line-height: 12px;
	display: inline-block;
	vertical-align: middle;
	margin: -15px 0;
}


.input.negative-offset {
	margin-top: -15px;
	margin-bottom: -15px;
}

.input[readonly] {
	cursor: default;
}

/*timepicker*/
timepicker {
	display: block;
	width: 100%; height: auto;
	position: relative;
	min-width: 80px;

	input.input {
		text-align: center;
	}

	&.inline {
		margin: -10px 0;
		display: inline-block;
		vertical-align: middle;

		.dropdown-menu {
			width: 100px;
			left: 50%;
			margin-left: -50px;


			.content {
				padding: 10px;
			}
			select.input {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
}


.input.paddless {
	padding-left: 10px;
	padding-right: 10px;

	& ~ timepicker {

		select {
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}


/* range */
range {
	position: relative;
	display: block;
	width: 100%; height: auto;

	.handle {
		position: absolute;
		top: 0;
		width: 20px; height: 20px;
		background: #000;
	}
}


.form-group.inline {
	display: inline-block;
	vertical-align: baseline;

	.input {
		line-height: 20px;
		height: 20px;
		padding: 0;
		border-top: none;
		border-left: none;
		border-right: none;
		border-radius: 0;
	}
	select.input {
		padding-right: 25px;
	}
}

.input.textarea {
	resize: none;
	height: 80px;
}

.form-time-list {
	font-size: 0;
	margin: 0 -5px;

	.item {
		display: inline-block;
		vertical-align: top;
		padding: 0 5px;
	}
}
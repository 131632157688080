$font: arial, tahoma, sans-serif;

/*link*/
$blue: #165cd1;
$dark: #474b53;
$red: #ef1818;
$green: #41a85f;
$ocean: #09889b;
$grey: #b4b4b4;
$light: #f5f5f5;
$border: #e0e0e0;
$color: #7a7d84;
$focus: rgba($blue, .3);
$checkboxSize: 14px;
$border-color: $light;
$purple: #9365B8;
$yellow: #FAC51C;

@mixin placeholder($color){
	&::-webkit-input-placeholder {
		color: #{$color};
	}
	&:-moz-placeholder {
		color: #{$color};
	}
	&::-moz-placeholder {
		color: #{$color};
	}
	&:-ms-input-placeholder {
		color: #{$color};
	}
}

@mixin clear(){
	background: transparent;
	display: block;
	border-radius: 0;
	border: none;
}
@import 'variables.scss';

.badge {
	display: inline-block;
	vertical-align: middle;
	line-height: 20px;
	font-size: 12px;
	font-weight: 500;
	padding: 0 10px;
	border-radius: 3px;
	background: rgba(0,0,0,.1);
	color: $dark;

	&.primary {
		background: $blue;
		color: #fff;
	}
	&.success {
		background: $green;
		color: #fff;
	}
	&.error {
		background: $red;
		color: #fff;
	}
}
@import './variables.scss';

@font-face {
	font-family: 'icomoon';
	src:  url('assets/icons/icomoon.eot?egqd8n');
	src:  url('assets/icons/icomoon.eot?egqd8n#iefix') format('embedded-opentype'),
		url('assets/icons/icomoon.ttf?egqd8n') format('truetype'),
		url('assets/icons/icomoon.woff?egqd8n') format('woff'),
		url('assets/icons/icomoon.svg?egqd8n#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: middle;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.offset-right {
		margin-right: 10px;
	}
	&.offset-left {
		margin-left: 10px;
	}
	&.primary { color: $blue; }
	&.error { color: $red; }
	&.succes { color: $green; }
	&.blue { color: blue; }
	&.green { color: green; }
	&.yellow { color: yellow; }
	&.purple { color: purple; }
	&.red { color: red; }
}

.icon-map:before {
	content: "\e900";
}
.icon-drop-down:before {
	content: "\e901";
}
.icon-drop-left:before {
	content: "\e902";
}
.icon-drop-up:before {
	content: "\e903";
}
.icon-drop-right:before {
	content: "\e904";
}
.icon-search:before {
	content: "\e905";
}
.icon-power:before {
	content: "\e906";
}
.icon-cake:before {
	content: "\e907";
}
.icon-wallet:before {
	content: "\e908";
}
.icon-folder:before {
	content: "\e909";
}
.icon-user:before {
	content: "\e90a";
}
.icon-users:before {
	content: "\e90b";
}
.icon-user-edit:before {
	content: "\e90c";
}
.icon-user-add:before {
	content: "\e90d";
}
.icon-users-add:before {
	content: "\e90e";
}
.icon-user-remove:before {
	content: "\e90f";
}
.icon-user-premium:before {
	content: "\e910";
}
.icon-lock1:before {
	content: "\e911";
}
.icon-lock2:before {
	content: "\e912";
}
.icon-lock3:before {
	content: "\e913";
}
.icon-lock4:before {
	content: "\e914";
}
.icon-lock5:before {
	content: "\e915";
}
.icon-lock6:before {
	content: "\e916";
}
.icon-clock:before {
	content: "\e917";
}
.icon-angle-down:before {
	content: "\e918";
}
.icon-angle-up:before {
	content: "\e919";
}
.icon-angle-right:before {
	content: "\e91a";
}
.icon-angle-left:before {
	content: "\e91b";
}
.icon-file:before {
	content: "\e91c";
}
.icon-user-book:before {
	content: "\e91d";
}
.icon-user-card:before {
	content: "\e91e";
}
.icon-graph:before {
	content: "\e91f";
}
.icon-settings:before {
	content: "\e920";
}
.icon-arrows:before {
	content: "\e921";
}
.icon-arrows-vertical:before {
	content: "\e922";
}
.icon-reload:before {
	content: "\e923";
}
.icon-dollar:before {
	content: "\e924";
}
.icon-pencil:before {
	content: "\e925";
}
.icon-trash:before {
	content: "\e926";
}
.icon-dots-vertical:before {
	content: "\e927";
}
.icon-dots-horisontal:before {
	content: "\e928";
}
.icon-calendar:before {
	content: "\e929";
}
.icon-cross:before {
	content: "\e92a";
}
.icon-checked:before {
	content: "\e92b";
}
.icon-long-arrow:before {
	content: "\e92c";
}
.icon-long-down:before {
	content: "\e92d";
}
.icon-long-arrow-left:before {
	content: "\e92e";
}
.icon-long-up:before {
	content: "\e92f";
}
.icon-plus:before {
	content: "\e930";
}
.icon-minus:before {
	content: "\e931";
}
.icon-bell:before {
	content: "\e932";
}
.icon-undo:before {
	content: "\e933";
}
.icon-rendo:before {
	content: "\e934";
}
.icon-block:before {
	content: "\e935";
}
.icon-filter:before {
	content: "\e936";
}
.icon-bag:before {
	content: "\e937";
}
.icon-phone:before {
	content: "\e938";
}
.icon-mail:before {
	content: "\e939";
}
.icon-building:before {
	content: "\e93a";
}
.icon-headphones:before {
	content: "\e93b";
}
.icon-timer:before {
	content: "\e93c";
}
.icon-telegram:before {
	content: "\e93d";
}
.icon-viber:before {
	content: "\e93e";
}
.icon-comments:before {
	content: "\e93f";
}
.icon-comment:before {
	content: "\e940";
}
.icon-info:before {
	content: "\e941";
}
.icon-camera:before {
	content: "\e942";
}
.icon-arrow-left:before {
	content: "\e943";
}
.icon-arrow-up:before {
	content: "\e944";
}
.icon-arrow-right:before {
	content: "\e945";
}
.icon-arrow-down:before {
	content: "\e946";
}
.icon-vacation:before {
  content: "\e947";
}
.icon-doctor-bag:before {
  content: "\e948";
}
.icon-doctor:before {
  content: "\e949";
}
.icon-trip:before {
  content: "\e94a";
}
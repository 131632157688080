@import 'variables.scss';

.chart-group {
	box-shadow: 0 0 10px rgba(119, 119, 119, 0.15);
	border-radius: 5px;
	background-color: #fff;
	padding: 15px;

	.header {
		margin: 0 0 15px;

		.title {
			color: $color;
			font-size: 12px;
			font-weight: 400;
		}
	}

	.navigation {

		.btn-nav {
			color: $color;
			transition: color .3s;
			width: 25px;
			cursor: pointer;
			background: transparent;
			display: inline-block;
			vertical-align: middle;
			border: 0;

			&:hover {
				color: $blue;
			}
			&.btn-prev {
				text-align: left;
			}
			&.btn-next {
				text-align: right;
			}
		}
		.caption {
			color: $color;
			font-size: 8px;
			font-weight: 400;
			letter-spacing: 0.08px;
			line-height: 11px;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.chart-legend {
		font-size: 0;
		margin: 0 -7px 25px;

		.item {
			color: #474b53;
			font-size: 8px;
			font-weight: 400;
			letter-spacing: 0.08px;
			line-height: 11px;
			display: inline-block;
			vertical-align: top;
			padding: 0 7px;

			.point {
				display: inline-block;
				vertical-align: middle;
				height: 9px; width: 9px;
				border-radius: 50%;
			}
			.name {
				display: inline-block;
				vertical-align: middle;
				line-height: 9px;
				margin-left: 10px;
			}
		}
	}
	.graph-outer {
		font-size: 0;
	}
	svg {
		width: 100%;

		.label-text {
			font-family: Arial;
			font-size: 8px;
			text-anchor: middle;
			fill: #474b53;
			user-select: none;

			&.odd:not(.last) {
				display: none;
			}
		}
		.chart-curves {

			path {

				&:hover {

					~ path {
						stroke-dasharray: 0;
					}
				}
			}
		}
	}
}
@import 'variables.scss';

.table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	line-height: 1.2;

	td, th {
		border: 1px solid lighten($border, 5);
		padding: 5px 10px;

		&:first-child { border-left: none; }
		&:last-child { border-right: none; }
	}

	.search {
		display: inline-block;
		vertical-align: baseline;
		font-weight: normal;
		font-style: normal;
		background: $blue;
		color: #fff;
	}

	thead tr {
		position: relative;
		border-bottom: 1px solid lighten($border, 5);

		th {
			border-top: none;
		}
	}
	thead.faded {
		z-index: 3;
		box-shadow:
			0 1px 0 0 rgba(0,0,0,.03),
			0 2px 0 0 rgba(0,0,0,.03),
			0 3px 0 0 rgba(0,0,0,.03),
			0 4px 0 0 rgba(0,0,0,.03),
			0 5px 0 0 rgba(0,0,0,.03);
	}
	tfoot {
		border-top: 1px solid lighten($border, 5);

		td {
			padding-top: 10px;
		}
	}
	th {
		font-size: inherit;
		font-weight: 400;
		color: $grey;
		text-align: left;

		[data-sort-asc],
		[data-sort-desc] {
			display: inline-block;
			vertical-align: middle;
			width: 6px;
			text-align: center;
			text-indent: -3px;
			font-size: 12px;
			color: $grey;
			position: relative;
			bottom: 1px;

			&:before {
				display: block;
				width: 100%; height: 12px;
				line-height: 12px;
				text-align: center;
			}
			&.active {
				color: $dark;
			}
		}
		&.active {
			color: $dark;
		}
	}
	tr:first-child {

		td, th {
			border-top: none;
		}
	}
	tr:last-child {

		td, th {
			border-bottom: none;
		}
	}
	tr.highlighted {
		background: lighten($blue, 45) !important;

		td {
			border-color: lighten($blue, 40);
		}
	}
}

.table.auto {
	table-layout: auto;
}

.table.small {

	td {
		padding-top: 2px;
		padding-bottom: 2px;
		font-size: 12px;
	}
	tfoot td {
		padding-top: 10px;
	}
}

.table.collapsed {

	td, th {
		padding: 3px 0;
		font-size: 12px;
	}
	th {
		text-align: center;
	}

}

.table.stripped {

	tbody {

		tr:nth-child(odd){
			background: $light;
		}
	}
}

.table.fake-heading {
	position: relative;
	z-index: 3;
}

sortable {

	.table {

		thead {

			th {
				cursor: pointer;

				&:hover {
					color: $color;
				}
			}
		}
	}
}


.timeline-table {
	display: block;
	font-size: 0;

	.rooms-col,
	.timeline-col {
		display: inline-block;
		vertical-align: top;
	}
	.rooms-col {
		width: 50px;
		padding-top: 30px;

		li {
			padding: 10px;
			margin-bottom: 10px;
		}
		img {
			display: block;
			width: 32px; height: 32px;
			border-radius: 50%;
		}
	}
	.timeline-col {
		width: calc(100% - 50px);
		font-size: 12px;
		cursor: default;

		ul:not(.timeline-heading) > li {
			height: 52px;
			padding: 5px;
			margin-bottom: 10px;

			div {
				line-height: 16px;
				font-size: 14px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-bottom: 3px;
			}
			span {
				display: table;
				width: 100%;
				table-layout: fixed;
				border-collapse: collapse;

				i {
					font-style: normal;
					display: table-cell;
					border: 1px solid rgba(0,0,0,.1);
					line-height: 16px;
					height: 16px;
					font-size: 11px;
					text-align: center;
					cursor: pointer;

					&:nth-child(8n+1),
					&:nth-child(8n+2),
					&:nth-child(8n+3),
					&:nth-child(8n+4) {
						background: rgba(0,0,0,.07);
					}
					&[v="1"] {
						background: rgba($blue, .4);
						color: #fff;
					}
					&[v="2"] {
						background: rgba($green, 1);
						color: #fff;
					}
					&:hover {
						background: $green;
						color: #fff;
						box-shadow: 0 0 0 2px $green;

						&[v="1"] {
							background: $red;
							box-shadow: 0 0 0 2px $red;
						}
						&[v="2"] {
							background: rgba($green, 1);
							color: #fff;
						}
						&[d="30"] {

							&,
							& + i {
								background: $green;
								color: #fff;

								&[v="1"] {
									color: #fff;
									background: $red;
								}
							}
						}
						&[d="45"] {

							&,
							& + i,
							& + i + i {
								background: $green;
								color: #fff;

								&[v="1"] {
									color: #fff;
									background: $red;
								}
							}
						}
						&[d="60"] {

							&,
							& + i,
							& + i + i,
							& + i + i + i {
								background: $green;
								color: #fff;

								&[v="1"] {
									color: #fff;
									background: $red;
								}
							}
						}
						&[d="75"] {

							&,
							& + i,
							& + i + i,
							& + i + i + i,
							& + i + i + i + i {
								background: $green;
								color: #fff;

								&[v="1"] {
									color: #fff;
									background: $red;
								}
							}
						}
						&[d="90"] {

							&,
							& + i,
							& + i + i,
							& + i + i + i,
							& + i + i + i + i,
							& + i + i + i + i + i {
								background: $green;
								color: #fff;

								&[v="1"] {
									color: #fff;
									background: $red;
								}
							}
						}

					}
				}
			}

			&:hover,
			&.active {
				background: rgba($blue, .05);

				div {
					color: $blue;
				}
				span {

					i[v="1"] {
						background: $blue;
						color: #fff;
					}
					i[v="2"] {
						background: $green;
					}
				}
			}
		}
		.timeline-heading {
			display: table;
			height: 20px;
			margin-bottom: 10px;
			border: 1px solid #eee;
			width: calc(100% - 10px);
			margin-left: 5px;
			table-layout: fixed;
			border-collapse: collapse;

			> li {
				display: table-cell;
				font-size: 12px;
				text-align: center;
				line-height: 20px;

				&:nth-child(odd){
					background: $light;
				}
			}
		}
	}
}
.timeline-demo {
	display: block;
	margin-bottom: 1px;
	white-space: nowrap;

	> li {
		display: inline-block;
		width: 20px; height: 16px;
		border: 1px solid rgba(0,0,0,.1);
		line-height: 16px;
		font-size: 12px;
		text-align: center;
		background: $green;
		color: #fff;
		cursor: default;

		&.active ~ li:not(.active) {
			background: initial;
			color: $dark;
		}
	}
}



.table.links {

	tbody {

		tr {
			cursor: pointer;

			&:hover {
				background: rgba(0,0,0,.01);
			}
		}
	}
}
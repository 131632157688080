@import 'variables.scss';

calendar {
	display: block;
	background: #fff;
	user-select: none;
	border-radius: 3px;

	.layout {
		display: table;
		width: 100%;
		table-layout: fixed;
		height: 100%;

		.column {
			display: table-cell;
			height: 100%;
			vertical-align: middle;
			width: auto;

			&.info-column {
				width: 33%;
				border-right: 1px solid $light;

				& ~ .column {
					width: 66%;
				}
			}
		}
	}
	.day-info {
		text-align: center;
		padding: 10px;

		& + .day-info {
			border-top: 1px solid $light;
		}
		.date,
		.date-name,
		.date-day,
		.date-year {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.date {
			font-size: 32px;
			font-weight: 300;
			line-height: 1;
			color: $blue;
		}
		.date-name {
			font-size: 13px;
		}
		.date-day {
			margin-top: 0px;
			font-size: 12px;
			color: $grey;
		}
		.date-year {
			font-size: 13px;
		}
	}
	.titles {
		display: table;
		table-layout: fixed;
		width: 100%;
		border-collapse: collapse;
		background: rgba($blue, .05);
		margin-bottom: 5px;

		> li {
			display: table-cell;
			vertical-align: top;
			border-left: 1px solid rgba($blue, .05);
			padding: 0 10px;
			font-size: 10px;
			line-height: 26px;
			font-weight: bold;
			position: relative;
			text-align: center;

			&.weekend {
				color: $red;
			}
			&:first-child {
				border-left: none;
			}
			&.active {
				color: $blue;
				box-shadow: inset 0 -2px 0 0 $blue;
			}
		}
	}
	.header {
		height: 40px;
		position: relative;
		padding: 0 40px;

		.month-name,
		.month-year {
			line-height: 1.1;
			display: block;
			font-size: 14px;
			text-align: center;
		}
		.month-year {
			font-size: 10px;
			padding-top: 5px;
			color: $grey;
		}
		.prev,
		.next {
			position: absolute;
			top: 5px;

			&.disabled {
				opacity: .2;
			}
		}
		.prev {
			left: 5px;
		}
		.next {
			right: 5px;
		}
	}

	.day {
		font-size: 13px;
		width: 30px;
		color: $color;
		line-height: 30px; height: 30px;
		border-radius: 50%;
		display: inline-block;
		text-align: center;
		cursor: pointer;
		position: relative;
		z-index: 2;

		&:hover {
			background: $light;
		}
		&.today {
			font-weight: 600;
			color: $blue;
		}
		&.active {
			background: $blue;
			color: #fff;
			font-weight: 600;
			box-shadow: 0 2px 10px rgba($blue, .5);
			z-index: 4;
		}
		&.offsetted {
			opacity: .3;
			font-size: 10px;
			pointer-events: none;
		}
		&.disabled {
			opacity: .3;
			/*text-decoration: line-through;*/
			pointer-events: none;
		}
	}
	.table {

		th, td {
			border: none;
			padding: 0;
			text-align: center;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 100%; height: 24px;
				background: $light;
				position: absolute;
				z-index: 1;
				top: 50%; left: 50%;
				margin-top: -12px;
				pointer-events: none;
				opacity: 0;
				transform: translateX(-50%);
			}
			&.highlighted {

				&:before {
					opacity: 1;
				}
				&.first:before {
					transform: translateX(0);
				}
				&.last:before {
					left: auto;
					right: 50%;
					transform: translateX(0);
				}
			}
		}
	}
	.months-wrapper {
		white-space: nowrap;
		overflow: hidden;

		.inner {
			/*transition: .2s cubic-bezier(0,.5,0,1);*/
			width: 100%; height: auto;
			white-space: nowrap;
		}
		.month {
			display: inline-block;
			/*transition: .2s;*/
			opacity: 0;

			&.visible {
				opacity: 1;
			}
		}
	}
}


calendar.collapsed {

	.layout {

		.column {
			display: none;

			&.info-column {
				display: table-cell;
				width: 100%;
			}
		}
	}

	.day-info {
		text-align: center;
		padding: 10px 0;

		& + .day-info {
			border-top: 1px solid $light;
		}
		.date {
			font-size: 24px;
		}
		.date-name {
			font-size: 13px;
		}
		.date-day {
			margin-top: 2px;
			font-size: 10px;
		}
		.date-year {
			font-size: 10px;
		}
	}

	.day-navigation {
		display: none;
	}

}


.calendar-day {
	white-space: nowrap;
	text-align: center;
	line-height: 1;
	overflow: hidden;
	text-overflow: ellipsis;

	& ~ .calendar-day {
		font-size: 16px;
	}
}


calendar.small {

	.header {
		height: 30px;

		.month-year {
			padding-top: 1px;
		}
		.prev,
		.next {
			top: 0;
		}
	}
	.day {
		width: 20px; height: 20px;
		line-height: 20px;
	}
	.titles {

		> li {
			line-height: 20px;
		}
	}
}


calendar.readonly {
	pointer-events: none;
}
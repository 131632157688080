@import 'variables.scss';

ul.quiz {
	list-style: none;
	user-select: none;
	padding-left: 20px;
	counter-reset: index-counter;

	li {
		margin-bottom: 2px;
		padding: 0 2px;
		line-height: 20px;
		height: 20px;
		counter-increment: index-counter;
		position: relative;

		&:before {
			content: counter(index-counter);
			position: absolute;
			width: 16px; height: 16px;
			background: rgba(0,0,0,.1);
			line-height: 16px;
			border-radius: 50%;
			text-align: center;
			top: 2px;
			font-size: 10px;
			overflow: hidden;
			left: -20px;
		}

		.text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&[ngDraggable] {
			cursor: move;
		}

		&:hover {
			background: rgba(0,0,0,.05);
		}

		&.drag-active {
			opacity: .5;
			pointer-events: none;
			filter: grayscale(1);
		}
		&.drop-attaching {
			position: relative;
			color: #fff;
			background: $blue;

			&:before {
				background: $blue;
			}
		}
	}
	ul {
		padding-left: 20px;
	}
}

#dragTarget {
	position: absolute;
	z-index: 100;
	cursor: move;
	pointer-events: none;
	opacity: 0;

	li {
		list-style: none;
		background: #fff;
		padding: 0 2px;
		box-shadow: 0 0 0 1px #ddd;
		opacity: .5;

		.text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	&.active {
		opacity: 1;
	}
}


.quiz-question {
	position: relative;
	margin-left: 40px;

	.counter {
		width: 30px; height: 30px;
		line-height: 30px;
		text-align: center;
		background: $blue;
		color: #fff;
		font-size: 18px;
		font-weight: 500;
		border-radius: 50%;
		position: absolute;
		top: 0; left: -45px;
	}

	& + .quiz-question {
		margin-top: 20px;
	}
}

.quiz-form-control {
	padding: 5px 10px;
	border-radius: 3px;
	line-height: 20px;
	font-size: 14px;
	margin: 0 -10px;

	.input {
		font-size: inherit;
		max-width: 200px;
	}

	.checkbox.round {
		margin-bottom: -4px;
		margin-left: 10px;

		label {
			margin: 0;
		}
	}

	& + .quiz-form-control {
		margin-top: 4px;
	}
	&.error {
		box-shadow: inset 4px 0 0 0 $red;
	}
}


.quiz-checkboxes {
	text-align: left;

	> li {
		display: inline-block;
		vertical-align: top;
		width: 200px;
	}
}
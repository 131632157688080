@import 'variables.scss';

scrollable {
	display: block;
	width: 100%; height: 100%;
	position: relative;

	*,
	:before,
	:after {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	$width: 10px;
	$scrollbarOffset: 2px;

	.scrollable-content {
		width: 100%; height: 100%;
		overflow: hidden;
		transform: none;

		&.inner-space {
			padding-right: $width;
		}
		&.inner-space-vertical {
			padding-bottom: $width;
		}
	}

	div {
		display: block;
	}

	.scrollbar {
		position: absolute;
		top: $scrollbarOffset; right: $scrollbarOffset;
		width: calc(#{$width} - #{$scrollbarOffset * 2});
		height: calc(100% - #{$scrollbarOffset * 2});
		z-index: 5;
		display: none;
		transition: opacity .3s;
		opacity: 0;
		background: rgba(0,0,0,.1);
		border-radius: calc(#{$width} / 2);

		.thumb {
			position: relative;
			width: 100%; height: 0%;
			cursor: pointer;
			z-index: 2;
			transition: .2s opacity;
			opacity: .5;
			background: $blue;
			border-radius: calc(#{$width} / 2);

/*			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0; left: -10px;
				height: 100%; right: 0;
			}
*/
			&:hover {
				opacity: 1;
			}
			&:active {
				opacity: 1;
			}
		}

		&.active {
			display: block;
		}
		&[data-origin="left"] {
			left: 0; right: auto;
		}

		&.horizontal {
			bottom: 0; left: 0;
			top: auto; right: auto;
			width: 100%; height: $width;

			.thumb {
				width: 0; height: 100%;
			}
		}
	}

	&.has-buttons {

		.scrollbar {
			top: $width; height: calc(100% - #{$width * 2});

			&.horizontal {
				top: auto; height: $width;
				left: $width; width: calc(100% - #{$width * 2});
			}
		}
	}

	&.bordered {
		border: 1px solid $border-color;
		border-radius: 4px;
	}

	&:hover,
	&:active {

		.scrollbar {
			opacity: 1;
		}
	}
}
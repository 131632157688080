$gutter: 15px;


.row,
.row.reverse {
	box-orient: horizontal;
}
.container,
.container-fluid {
	margin: 0 auto;
	padding-right: $gutter;
	padding-left: $gutter;
}
.container {
	max-width: 1190px;
}
.row {
	box-sizing: border-box;
	display: block;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex: 0 1 auto;
	-webkit-box-flex: 0;
	flex: 0 1 auto;
	-ms-flex-direction: row;
	-webkit-box-direction: normal;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -$gutter;
	margin-left: -$gutter;

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.row.collapsed-row {
	margin-right: 0;
	margin-left: 0;

	> * {
		padding-left: 0;
		padding-right: 0;
	}
}

.row.paddless-row {
	margin-right: -5px;
	margin-left: -5px;

	> * {
		padding-left: 5px;
		padding-right: 5px;
	}
}

[class*="col-xs-"],
[class*="col-ms-"],
[class*="col-sm-"],
[class*="col-md-"],
[class*="col-lg-"] {
	float: left;
	box-sizing: border-box;
	-ms-flex: 0 0 auto;
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	padding-right: $gutter;
	padding-left: $gutter;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	-webkit-box-flex: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	max-width: 100%;
}

@mixin generateCells($prefix) {
	@for $i from 1 through 12 {
		.col-#{$prefix}-#{$i} {
			-ms-flex-preferred-size: #{($i / 12) * 100%};
			flex-basis: #{($i / 12) * 100%};
			max-width: #{($i / 12) * 100%};
			width: #{($i / 12) * 100%};
		}
	}
	@for $i from 0 through 12 {
		.col-#{$prefix}-offset-#{$i} {
			margin-left: #{($i / 12) * 100%};
		}
	}
}

@mixin generateHelpers($prefix){
	.#{$prefix}-hide { display: none; }
	.#{$prefix}-inline { display: inline; }
	.#{$prefix}-show { display: inline-block; }
	.#{$prefix}-block { display: block; }
	.start-#{$prefix} {
		-ms-flex-pack: start;
		-webkit-box-pack: start;
		justify-content: flex-start;
		text-align: start;
	}
	.center-#{$prefix} {
		-ms-flex-pack: center;
		-webkit-box-pack: center;
		justify-content: center;
		text-align: center;
	}
	.end-#{$prefix} {
		-ms-flex-pack: end;
		-webkit-box-pack: end;
		justify-content: flex-end;
		text-align: end;
	}
	.top-#{$prefix} {
		-ms-flex-align: start;
		-webkit-box-align: start;
		align-items: flex-start
	}
	.middle-#{$prefix} {
		-ms-flex-align: center;
		-webkit-box-align: center;
		align-items: center
	}
	.bottom-#{$prefix} {
		-ms-flex-align: end;
		-webkit-box-align: end;
		align-items: flex-end
	}
	.around-#{$prefix} {
		-ms-flex-pack: distribute;
		justify-content: space-around
	}
	.between-#{$prefix} {
		-ms-flex-pack: justify;
		-webkit-box-pack: justify;
		justify-content: space-between
	}
	.first-#{$prefix} {
		-ms-flex-order: -1;
		-webkit-box-ordinal-group: 0;
		order: -1
	}
	.last-#{$prefix} {
		-ms-flex-order: 1;
		-webkit-box-ordinal-group: 2;
		order: 1
	}
}

.hidden,
.none,

.ms-show,
.sm-show,
.md-show,
.lg-show,

.ms-inline,
.sm-inline,
.md-inline,
.lg-inline,

.ms-block,
.sm-block,
.md-block,
.lg-block {
	display: none;
}

/*xs*/
@include generateCells(xs);
@include generateHelpers(xs);
/*ms*/
@media (min-width: 481px) {
	@include generateCells(ms);
	@include generateHelpers(ms);
}
/*sm*/
@media (min-width: 769px) {
	@include generateCells(sm);
	@include generateHelpers(sm);
}
/*md*/
@media (min-width: 993px) {
	@include generateCells(md);
	@include generateHelpers(md);
}
/*lg*/
@media (min-width: 1201px) {
	@include generateCells(lg);
	@include generateHelpers(lg);
}
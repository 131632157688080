@import 'variables.scss';

popover,
.popover {
	display: none;
	background: #fff;
	padding: 10px 15px;
	border-radius: 5px;
	width: 340px;
	font-size: 13px;
	position: absolute;
	z-index: 10;
	box-shadow: 0 10px 30px rgba(0,0,0,.2);
	white-space: normal;

	.main-title {
		margin-bottom: 10px;
	}
	.close {
		position: absolute;
		top: 7px; right: 7px;
	}

	&:before {
		content: '';
		display: block;
		width: 10px; height: 10px;
		position: absolute;
		background: inherit;
		transform: rotate(45deg);
	}

	&.open {
		display: block;
	}
}

.popover {
	margin: 0;
	transform: translate(0, -100%);
	position: absolute;
	z-index: 5;
}

popover.origin-right,
.popover.origin-right {
	top: 50%; left: 100%;
	margin-top: -30px;
	margin-left: 10px;

	&:before {
		left: -5px; top: 25px;
	}
}

popover.origin-left {
	top: 50%; right: 100%;
	margin-top: -30px;
	margin-right: 10px;

	&:before {
		right: -5px; top: 25px;
	}
}
.popover.origin-left {
	transform: translate(-100%, -100%);
	margin-left: 80px;
	margin-top: -10px;

	&:before {
		bottom: -5px; right: 50px;
		left: auto;
	}
}

popover.origin-top,
.popover.origin-top {
	left: 0; bottom: 100%;
	margin-left: -30px;
	margin-bottom: 10px;

	&:before {
		bottom: -5px; left: 50px;
	}
}
.popover.origin-top {
	bottom: auto;
	margin-top: -10px;
}

popover.origin-bottom,
.popover.origin-bottom {
	left: 0; top: 100%;
	margin-left: -30px;
	margin-top: 10px;

	&:before {
		top: -5px; left: 50px;
	}
}